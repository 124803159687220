	/*
  	Flaticon icon font: Flaticon
  	Creation date: 22/06/2016 15:07
  	*/

@font-face {
  font-family: "flaticon-dashboard";
  src: url("fonts/dashboard.ttf") format("truetype"),
       url("fonts/dashboard.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "flaticon-dashboard";
    src: url("fonts/dashboard.svg#Flaticon") format("svg");
  }
}

[class^="fi-dashboard-"]:before, [class*=" fi-dashboard-"]:before,
[class^="fi-dashboard-"]:after, [class*=" fi-dashboard-"]:after {   
  font-family: 'flaticon-dashboard';
        font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.fi-dashboard-bell-outline:before { content: "\f100"; }
.fi-dashboard-big-gross-short-pencil-outline:before { content: "\f101"; }
.fi-dashboard-book-outline:before { content: "\f102"; }
.fi-dashboard-cellphone-back:before { content: "\f103"; }
.fi-dashboard-circular-clock:before { content: "\f104"; }
.fi-dashboard-dashboard:before { content: "\f105"; }
.fi-dashboard-download-internet-cloud-symbol:before { content: "\f106"; }
.fi-dashboard-file-rounded-rectangular-symbol-with-down-arrow:before { content: "\f107"; }
.fi-dashboard-filter-outline:before { content: "\f108"; }
.fi-dashboard-graphic:before { content: "\f109"; }
.fi-dashboard-heart-shape-of-two-lines:before { content: "\f10a"; }
.fi-dashboard-link-symbol:before { content: "\f10b"; }
.fi-dashboard-logout:before { content: "\f10c"; }
.fi-dashboard-new-email-back-closed-envelope-symbol:before { content: "\f10d"; }
.fi-dashboard-oil-drop-outline:before { content: "\f10e"; }
.fi-dashboard-opening-email:before { content: "\f10f"; }
.fi-dashboard-oval-speech-bubble:before { content: "\f110"; }
.fi-dashboard-picture-outline:before { content: "\f111"; }
.fi-dashboard-pie-graphic:before { content: "\f112"; }
.fi-dashboard-placeholder-outline-maps-interface-tool:before { content: "\f113"; }
.fi-dashboard-play-symbol-on-photo-video-camera:before { content: "\f114"; }
.fi-dashboard-plug-outline:before { content: "\f115"; }
.fi-dashboard-puzzle-piece-outline:before { content: "\f116"; }
.fi-dashboard-reminder-paper-with-a-pin:before { content: "\f117"; }
.fi-dashboard-rhombus-outline-shape-with-a-circle-at-center:before { content: "\f118"; }
.fi-dashboard-rounded-cellphone-back:before { content: "\f119"; }
.fi-dashboard-rounded-paper-with-text-lines-and-one-folded-corner:before { content: "\f11a"; }
.fi-dashboard-rounded-phone-back:before { content: "\f11b"; }
.fi-dashboard-shield-outlined-shape:before { content: "\f11c"; }
.fi-dashboard-speedometer-tool:before { content: "\f11d"; }
.fi-dashboard-star-badge-symbol-outline:before { content: "\f11e"; }
.fi-dashboard-thumb-up-outline-symbol:before { content: "\f11f"; }
.fi-dashboard-trash-can:before { content: "\f120"; }
.fi-dashboard-triangular-rocket-outline-symbol:before { content: "\f121"; }
.fi-dashboard-user-outline:before { content: "\f122"; }
.fi-dashboard-wheel-outline:before { content: "\f123"; }

    